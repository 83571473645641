import React from 'react';
import { compose, withProps } from 'recompose';
import { inject, observer } from 'mobx-react';

import './style.scss';
import { STORE_KEYS } from '../../stores';

const Header = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);

  // eslint-disable-next-line react/prop-types
  const { viewMode, setViewMode } = props;

  setInterval(() => {
    if (window.innerWidth > 992 && isOpen === true) {
      setIsOpen(false);
    }
  }, 10);

  const toggleContainer = React.createRef();

  const onClickOutsideHandler = (event) => {
    if (isOpen && toggleContainer.current && !toggleContainer.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener('click', onClickOutsideHandler);
  });

  return (
  // eslint-disable-next-line react/prop-types
    <div className={`${viewMode}_theme`}>
      <div className="app-header-v2">
        <div className="container">
          <div className="header">
            <div className="logo">
              <a href="/"><img src="/images/logo.svg" alt="logo" /></a>
            </div>
            <div className="nav-menu" id="nav-menu">
              <div className="menu-list">
                <div className="menu-item">
                  <a href="/">NETWORK STATS</a>
                </div>
                <div className="menu-item">
                  <a href="/latest-block">LATEST BLOCKS</a>
                </div>
                <div className="menu-item">
                  <a href="/rich-list">RICH LIST</a>
                </div>
              </div>
              <div className="button-group">
                <ThemePanel setViewMode={setViewMode} />
              </div>
              <div className="collapse-nav-menu" style={{ display: isOpen ? 'flex' : 'none' }} ref={toggleContainer}>
                <div className="menu-item">
                  <a href="/">NETWORK STATUS</a>
                </div>
                <div className="menu-item">
                  <a href="/latest-block">LATEST BLOCKS</a>
                </div>
                <div className="menu-item">
                  <a href="/rich-list">RICH LIST</a>
                </div>
              </div>
              <button className="nav-button" type="button" onClick={() => setIsOpen(!isOpen)}>
                <img src="/images/bars.png" alt="" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// eslint-disable-next-line react/prop-types
const ThemePanel = ({ setViewMode }) => (
  <div className="theme-panel hidden-xs hidden-sm">
    {/* eslint-disable-next-line react/prop-types */}
    <div className="toggler" onClick={() => setViewMode()} />
  </div>
);

export default compose(
  inject(STORE_KEYS.VIEWMODESTORE),
  observer,
  withProps(
    ({
      [STORE_KEYS.VIEWMODESTORE]: {
        viewMode,
        setViewMode,
      },
    }) => ({
      viewMode,
      setViewMode,
    }),
  ),
)(Header);
