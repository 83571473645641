import React from 'react';

const ProgressBar = (props) => {
  // eslint-disable-next-line no-unused-vars,react/prop-types
  const { width } = props;

  return (
    <div className="progress">
      <div className="progress-bar" style={{ width: `${width}%` }} />
    </div>
  );
};

export default ProgressBar;
