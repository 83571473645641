import React from 'react';
import './style.scss';

const InfoCard = (props) => {
  // eslint-disable-next-line react/prop-types
  const { headText, bodyText } = props;

  return (
    <div className="card">
      <div className="card-header">
        <div className="icon">
          <div className="bar" />
          <div className="circle-border">
            <div className="circle" />
          </div>
        </div>
        { headText }
      </div>
      <div className="card-body">
        { bodyText }
      </div>
    </div>
  );
};

export default InfoCard;
