import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';

const PublicRoute = ({ component: C, props: cProps, ...rest }) => (
  <>
    <Header />
    <Route
      {...rest}
      render={(props) => (
        <C {...props} {...cProps} match={rest.computedMatch} />
      )}
    />
    <Footer />
  </>
);

PublicRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]).isRequired,
  props: PropTypes.object.isRequired,
};

export default PublicRoute;
