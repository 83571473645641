/* eslint-disable */
import { observable, action } from 'mobx';
import {
  getLatestHistory,
} from '../lib/rest';

class LatestTxStore {
  @observable latestTx;
  intvalHandle = null;

  constructor() {
    clearInterval(this.intvalHandle);
    this.intvalHandle = setInterval(() => {
      this.getLtHistory();
    }, 15000);
    this.getLtHistory();
  }

  @action.bound getLtHistory() {
    getLatestHistory()
      .then((ret) => {
        this.latestTx = ret.data || [];
      });
  }
}

export default () => new LatestTxStore();
