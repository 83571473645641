/* eslint-disable */
import { observable, action } from 'mobx';
import {
  getPegnetBalance,
  getPegnetTransactions,
  getPegnetTxDetail,
} from '../lib/rest';

class PegnetCoreStore {
  @observable mbalance;
  @observable mTxList;
  @observable totalCount = 0;
  @observable pageIndex = 0;
  @observable mTxDetail;

  constructor() {
    // this.getPegnetTxDetail('0-5f68fe43809f28b1608a334cbe49d0ab1dfa1a858e579c37143d6751c800f348');
  }

  @action.bound getPegnetBalance(address) {
    getPegnetBalance(address)
      .then((ret) => {
        this.mbalance = ret.data;
      });
  }

  @action.bound getPegnetTransactions(address) {
    getPegnetTransactions(address, this.pageIndex)
      .then((ret) => {
        this.mTxList = ret.data;
        this.totalCount = ret.data.totalCount;
      });
  }

  @action.bound getPegnetTxDetail(txId) {
    getPegnetTxDetail(txId)
      .then((ret) => {
        this.mTxDetail = ret.data;
      });
  }

  @action.bound nextPage(address) {
    const pages = Math.floor(this.totalCount / 50);
    console.log('pages:', pages, this.pageIndex);
    this.pageIndex = this.pageIndex < pages ? this.pageIndex + 1 : pages;
    this.getPegnetTransactions(address);
  }

  @action.bound prevPage(address) {
    this.pageIndex = this.pageIndex <= 0 ? 0 : this.pageIndex - 1;
    this.getPegnetTransactions(address);
  }
}

export default () => new PegnetCoreStore();
