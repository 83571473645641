import React, { Component } from 'react';
import { compose, withProps } from 'recompose';
import { inject, observer } from 'mobx-react';
import './style.scss';
import { Code } from './styles';
import { STORE_KEYS } from '../../stores';
import InfoCard from '../../components/Common/InfoCard';
import { formatNumberString } from '../../utils';
import PaginationControl from './PaginationControl';

class Balances extends Component {
  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    const { token } = this.props.match.params;
    const {
      // eslint-disable-next-line react/prop-types
      getPegnetBalance, getPegnetTransactions,
    } = this.props;

    getPegnetBalance(token);
    getPegnetTransactions(token);
  }

  getXName = (sym) => {
    if (sym === 'pXBT') {
      return 'pBTC';
    }
    if (sym === 'pXBC') {
      return 'pBCH';
    }
    if (sym === 'pXAU') {
      return 'pGOLD';
    }
    if (sym === 'pXAG') {
      return 'pSilver';
    }
    return sym;
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { token } = this.props.match.params;
    const {
      // eslint-disable-next-line react/prop-types
      mbalance, mTxList, pAssetsRates, totalCount,
    } = this.props;

    /**
     *  Transactions
     */
    // eslint-disable-next-line react/prop-types
    const totalTxCount = mTxList ? mTxList.count : 0;
    // eslint-disable-next-line react/prop-types
    const mTxListData = ((mTxList ? mTxList.actions : []) || []);
    const firstTx = mTxListData[0] ? mTxListData[0].txid : '';
    let numberOfConversion = 0;
    let numberOfTransfer = 0;
    // eslint-disable-next-line array-callback-return
    mTxListData.map((item) => {
      if (item.txaction === 2) {
        numberOfConversion++;
      } else if (item.txaction === 1) {
        numberOfTransfer++;
      }
    });

    /**
     *  My Balances
     */
    const myBalances = [];
    let finalBalanceUSD = 0;
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const asset in mbalance) {
      myBalances.push([asset, mbalance[asset]]);
      try {
        finalBalanceUSD += (mbalance[asset] / 1e8) * (pAssetsRates[asset] / 1e8);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }
    myBalances.sort((a, b) => b[1] - a[1]);

    /**
     *  Rendering
     */
    return (
      <>
        <section className="address-detail-section-v2">
          <div className="container">
            <div className="sub-title">
              <h3>Details for Address</h3>
              <p>
                All payments and transactions cost 1/10th of a cent whether you
                <br />
                send five cents or five million dollars.
              </p>
            </div>
            <div className="table-address">
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <td className="name">Address:</td>
                    <td>{token}</td>
                  </tr>
                  <tr>
                    <td className="name">Recent Tx:</td>
                    <td>
                      {/* eslint-disable-next-line react/jsx-no-target-blank */}
                      <a href={`/transactions/${firstTx}`}>{firstTx}</a>
                    </td>
                  </tr>
                  <tr>
                    <td className="name">#. Transactions:</td>
                    <td>{totalTxCount}</td>
                  </tr>
                  <tr>
                    <td className="name">Total transfers:</td>
                    <td>{numberOfTransfer}</td>
                  </tr>
                  <tr>
                    <td className="name">Total conversions:</td>
                    <td>{numberOfConversion}</td>
                  </tr>
                  <tr>
                    <td className="name">Final Balance:</td>
                    <td>{`$${formatNumberString(finalBalanceUSD, 2)}`}</td>
                  </tr>
                </tbody>
              </table>
              <div className="qr-code">
                <Code value={token} />
              </div>
            </div>
          </div>
        </section>
        <section className="address-detail-section-v2">
          <div className="container">
            <div className="sub-title">
              <h3>Balances</h3>
              <p>
                Store value in any listed fiat currency, cryptocurrency, or precious metal.
                <br />
                Your balance is updated less than 10 minutes.
              </p>
            </div>
            <div className="row">
              {
                myBalances.map((item, index) => (
                  <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12" key={index}>
                    <InfoCard headText={this.getXName(item[0])} bodyText={item[1] ? `${item[1] / 1e8}` : '0.00'} />
                  </div>
                ))
              }
            </div>
          </div>
        </section>
        <section className="transactions-section">
          <div className="container">
            <div className="sub-title">
              <h3>Transactions</h3>
              <p>
                Transactions take a maximum of 10 minutes on the network. Conversions take
                <br />
                at least 10 minutes but not more than 20 minutes.
              </p>
            </div>
            <div className="table-transactions">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th className="hash">Date/Time</th>
                    <th className="block">TRANSATION ID</th>
                    <th className="date">AMOUNT</th>
                    <th className="amount">TYPE</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    // eslint-disable-next-line no-unused-vars,react/prop-types
                    mTxListData.slice(0, 150).map((item, index) => {
                      const d = new Date(item.timestamp);
                      const isFailedTx = item.executed < 0;
                      // eslint-disable-next-line max-len
                      const date = `${d.getFullYear()}-${`0${d.getMonth() + 1}`.substr(-2, 2)}-${`0${d.getDate()}`.substr(-2, 2)} ${`0${d.getHours()}`.substr(-2, 2)}:${`0${d.getMinutes()}`.substr(-2, 2)}`;
                      return (
                        <tr key={index}>
                          <td className={`hash ${isFailedTx && 'failed'}`}>{date}</td>
                          <td className={`block ${isFailedTx && 'failed'}`}>
                            {/* eslint-disable-next-line react/jsx-no-target-blank */}
                            <a href={`/transactions/${item.txid}`}>{item.txid}</a>
                          </td>
                          {/* eslint-disable-next-line max-len */}
                          <td className={`date ${isFailedTx && 'failed'}`}>{`${item.fromamount ? item.fromamount / 1e8 : 0} ${item.fromasset}`}</td>
                          {/* eslint-disable-next-line max-len */}
                          <td className={`balance ${isFailedTx && 'failed'}`}>{item.txaction === 2 ? `Conversion (${item.fromasset}->${item.toasset})` : 'Transfer'}</td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
              {
                totalCount > 0 && (
                  <PaginationControl totalCount={totalCount} address={token} />
                )
              }
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default compose(
  inject(STORE_KEYS.PEGNETCORESTORE, STORE_KEYS.NETWORKSTATSSTORE),
  observer,
  withProps(
    ({
      [STORE_KEYS.PEGNETCORESTORE]: {
        mbalance,
        mTxList,
        totalCount,
        getPegnetBalance,
        getPegnetTransactions,
      },
      [STORE_KEYS.NETWORKSTATSSTORE]: {
        pAssetsRates,
      },
    }) => ({
      mbalance,
      mTxList,
      totalCount,
      getPegnetBalance,
      getPegnetTransactions,
      pAssetsRates,
    }),
  ),
)(Balances);
