import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import HeaderV2 from '../components/HeaderV2';
import Footer from '../components/Footer';

const DetailsRoute = ({ component: C, props: cProps, ...rest }) => (
  <>
    <HeaderV2 />
    <Route
      {...rest}
      render={(props) => (
        <C {...props} {...cProps} match={rest.computedMatch} />
      )}
    />
    <Footer />
  </>
);

DetailsRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]).isRequired,
  props: PropTypes.object.isRequired,
};

export default DetailsRoute;
