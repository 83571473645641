import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;  
  margin-top: 30px;
`;

export const Button = styled.button`
    margin-right: 15px;
    margin-left: 15px;
    font-size: 15px;
    margin-top: -1px;
    border-radius: 4px;
    padding: 3px 15px;
    border: 1px solid #4cb0e2;
`;

export const Content = styled.div`
  font-size: 20px;
  margin: 0 30px;
  padding-bottom: 2px;
`;
