/* eslint-disable */
import { observable, action } from 'mobx';
import {
  getNetworkStats, getPegRates, getSyncData, getValidAddr,
} from '../lib/rest';

class NetworkStatsStore {
  @observable burnTotal;
  @observable conversionCount;
  @observable dominance;
  @observable hashrate;
  @observable nonZero;
  @observable pAssetsRates;
  @observable syncheight;
  @observable factomheight;

  intvalHandle = null;

  constructor() {
    this.getNtStatus();
    this.getPgRates();
    this.getSyData();

    clearInterval(this.intvalHandle);
    this.intvalHandle = setInterval(() => {
      this.getSyData();
    }, 15000);
  }

  @action.bound getNtStatus() {
    getNetworkStats()
      .then((data) => {
        this.burnTotal = data.burnTotal || 0;
        this.conversionCount = data.conversionCount || 0;
        this.dominance = data.dominance || [];
        this.hashrate = data.hashrate || 0;
        this.nonZero = data.nonZero || 0;
      });
  }

  @action.bound getPgRates() {
    getPegRates()
      .then((ret) => {
        this.pAssetsRates = ret.data || {};
      });
  }

  @action.bound getSyData() {
    getSyncData()
      .then((ret) => {
        this.syncheight = ret.syncheight || 0;
        this.factomheight = ret.factomheight || 0;
      });
  }

  @action.bound validateAddr(addr) {
    return new Promise((resolve, reject) => {
      getValidAddr(addr)
        .then((ret) => {
          const isValid = ret.data;
          resolve(isValid);
        });
    });
  }
}

export default () => new NetworkStatsStore();
