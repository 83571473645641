import React, { Component } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

// eslint-disable-next-line react/prefer-stateless-function
class DominationChart extends Component {
  render() {
    // eslint-disable-next-line react/prop-types
    const { domiData } = this.props;
    const data = [];
    // eslint-disable-next-line array-callback-return,react/prop-types
    (domiData || []).map((item) => {
      data.push({
        name: item.minerId || '',
        y: item.share || 0,
      });
    });

    const options = {
      chart: {
        type: 'pie',
      },
      title: {
        text: '',
      },
      series: [{
        data,
      }],
      plotOptions: {
        pie: {
          innerSize: '30%',
        },
      },
    };

    return (
      <div className="donutWrapper">
        {data.length > 0 && (
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
          />
        )}
      </div>
    );
  }
}

export default DominationChart;
