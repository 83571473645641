import React from 'react';
import { compose, withProps } from 'recompose';
import { inject, observer } from 'mobx-react';

import InfoCard from '../../components/Common/InfoCard';
import './style.scss';
import { STORE_KEYS } from '../../stores';
import DominationChart from './dominationChart';

function GerneralInformation(props) {
  const {
    // eslint-disable-next-line react/prop-types
    viewMode, burnTotal, conversionCount, hashrate, nonZero, dominance, pAssetsRates,
  } = props;

  return (
    <div className={`${viewMode}_theme`}>
      <section className="general-info-section">
        <div className="container">

          {/* PegNet Rates */}
          <div className="sub-title">
            <h3>PegNet Conversion Rates</h3>
            <p>
              All payments and transactions cost 1/10th of a cent whether you
              {' '}
              <br />
              send five cents or five million dollars.
            </p>
          </div>
          <div className="card-group row">
            {
              Object.keys(pAssetsRates || {}).map((item, index) => (
                <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12" key={index}>
                  <InfoCard headText={item} bodyText={pAssetsRates[item] ? `$${pAssetsRates[item] / 1e8}` : ''} />
                </div>
              ))
            }
          </div>

          <br />
          {/* PegNet Stats */}
          <div className="sub-title">
            <h3>PegNet Stats</h3>
            <p>
              PegNet uses a unique Proof of Work algorithm called
              {' '}
              <br />
              LXRHash which is only for CPUs.
            </p>
          </div>
          <div className="card-group row">
            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <InfoCard headText="Hashrate" bodyText={!hashrate ? '-' : `${hashrate / 1000000} Mh/s`} />
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <InfoCard headText="Conversions Executed Today" bodyText={!conversionCount ? '-' : conversionCount} />
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <InfoCard headText="Non-Zero Addresses" bodyText={!nonZero ? '-' : nonZero} />
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <InfoCard headText="Total FCT Converted" bodyText={!burnTotal ? '-' : burnTotal} />
            </div>
          </div>

          <br />
          {/* PegNet Miner Domination */}
          <div className="sub-title">
            <h3>PegNet Miner Domination</h3>
            <p>
              PegNet miner share of winning OPRs for the past 7 days.
            </p>
          </div>
          <div className="card-group row">
            <DominationChart domiData={dominance} />
          </div>
          <br />
          <br />
        </div>
      </section>
    </div>
  );
}

export default compose(
  inject(STORE_KEYS.VIEWMODESTORE, STORE_KEYS.NETWORKSTATSSTORE),
  observer,
  withProps(
    ({
      [STORE_KEYS.VIEWMODESTORE]: {
        viewMode,
      },
      [STORE_KEYS.NETWORKSTATSSTORE]: {
        burnTotal,
        conversionCount,
        dominance,
        hashrate,
        nonZero,
        pAssetsRates,
      },
    }) => ({
      viewMode,
      burnTotal,
      conversionCount,
      dominance,
      hashrate,
      nonZero,
      pAssetsRates,
    }),
  ),
)(GerneralInformation);
