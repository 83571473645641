import once from 'lodash/once';
import ViewModeStore from './ViewModeStore';
import NetworkStatsStore from './NetworkStatsStore';
import RichListStore from './RichListStore';
import LatestTxStore from './LatestTxStore';
import PegnetCoreStore from './PegnetCoreStore';

const VIEWMODESTORE = 'ViewModeStore';
const NETWORKSTATSSTORE = 'NetworkStatsStore';
const RICHLISTSTORE = 'RichListStore';
const LATESTTXSTORE = 'LatestTxStore';
const PEGNETCORESTORE = 'PegnetCoreStore';

export const STORE_KEYS = {
  VIEWMODESTORE,
  NETWORKSTATSSTORE,
  RICHLISTSTORE,
  LATESTTXSTORE,
  PEGNETCORESTORE,
};

export default once(() => {
  const viewModeStore = ViewModeStore();
  const networkStatsStore = NetworkStatsStore();
  const richListStore = RichListStore();
  const latestTxStore = LatestTxStore();
  const pegnetCoreStore = PegnetCoreStore();

  return {
    [STORE_KEYS.VIEWMODESTORE]: viewModeStore,
    [STORE_KEYS.NETWORKSTATSSTORE]: networkStatsStore,
    [STORE_KEYS.RICHLISTSTORE]: richListStore,
    [STORE_KEYS.LATESTTXSTORE]: latestTxStore,
    [STORE_KEYS.PEGNETCORESTORE]: pegnetCoreStore,
  };
});
