import React from 'react';
import { compose, withProps } from 'recompose';
import { inject, observer } from 'mobx-react';

import './style.scss';
import ProgressBar from '../../components/Common/ProgressBar';
import { STORE_KEYS } from '../../stores';
import { formatNumberString } from '../../utils';

const RichList = (props) => {
  // eslint-disable-next-line react/prop-types
  const { viewMode, richList } = props;
  let total = 0;
  // eslint-disable-next-line array-callback-return
  (richList || []).map((item) => {
    total += item.amount ? Number(item.amount / 1e8) : 0;
  });

  return (
    <div className={`${viewMode}_theme`}>
      <section className="rich-list-section">
        <div className="container">
          <div className="sub-title">
            <h3>Rich List</h3>
            <p>
              The list of rich addresses for PEG asset.
            </p>
          </div>
          <div className="table-rich-list">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th className="rank">Rank</th>
                  <th className="address">Address</th>
                  <th className="amount">Amount(PEG)</th>
                  <th className="percent">Percentage</th>
                  <th className="lastChange">pUSD</th>
                </tr>
              </thead>
              <tbody>
                {
                  // eslint-disable-next-line react/prop-types
                  (richList || []).map((item, index) => (
                    <tr key={index}>
                      <td className="rank">{index + 1}</td>
                      <td className="address text-color-blue">{item.address}</td>
                      <td className="amount">{item.amount ? formatNumberString(item.amount / 1e8, 2) : ''}</td>
                      <td className="percent">
                        {/* eslint-disable-next-line no-mixed-operators */}
                        <ProgressBar width={item.amount ? ((item.amount / 1e8) / total) * 100 + 10 : 0} />
                      </td>
                      <td className="lastChange">{item.pusd ? formatNumberString(item.pusd / 1e8, 2) : ''}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  );
};

export default compose(
  inject(STORE_KEYS.VIEWMODESTORE, STORE_KEYS.RICHLISTSTORE),
  observer,
  withProps(
    ({
      [STORE_KEYS.VIEWMODESTORE]: {
        viewMode,
      },
      [STORE_KEYS.RICHLISTSTORE]: {
        richList,
      },
    }) => ({
      viewMode,
      richList,
    }),
  ),
)(RichList);
