import { observable, action } from 'mobx';

class ViewModeStore {
  @observable viewMode;

  constructor() {
    this.viewMode = 'light';
  }

  @action.bound setViewMode() {
    if (this.viewMode === 'dark') {
      this.viewMode = 'light';
    } else {
      this.viewMode = 'dark';
    }
  }
}

export default () => new ViewModeStore();
