import styled from 'styled-components';
import QRCode from 'qrcode.react';

// eslint-disable-next-line import/prefer-default-export
export const Code = styled(QRCode).attrs({
  size: '100%',
  level: 'L',
  bgColor: 'white',
  includeMargin: true,
  renderAs: 'svg',
})``;
