/* eslint-disable */
import { observable, action } from 'mobx';
import {
  getRichList,
} from '../lib/rest';

class RichListStore {
  @observable richList;

  constructor() {
    this.getRcList();
  }

  @action.bound getRcList() {
    getRichList()
      .then((ret) => {
        this.richList = ret.data || [];
      });
  }
}

export default () => new RichListStore();
