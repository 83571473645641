import React from 'react';
import { compose, withProps } from 'recompose';
import { inject, observer } from 'mobx-react';
import './style.scss';
import { STORE_KEYS } from '../../stores';

function LatestBlock(props) {
  // eslint-disable-next-line react/prop-types
  const { latestTx: latestBlockData } = props;

  return (
    <>
      <section className="latest-blocks-section">
        <div className="container">
          <div className="sub-title">
            <h3>Latest Transactions</h3>
            <p>
              This list is updated every 15 seconds.
              <br />
              Search transaction with entry hash, EBlock, and address.
            </p>
          </div>
          <div className="table-latest-blocks">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th className="block-height">BLOCK HEIGHT</th>
                  <th className="transactions">TIMESTAMP</th>
                  <th className="age">ENTRY HASH</th>
                  <th className="value-out">AMOUNT</th>
                  <th className="difficulty">TYPE</th>
                  <th className="extracted-by">EBLOCK</th>
                </tr>
              </thead>
              <tbody>
                {
                  // eslint-disable-next-line no-unused-vars
                  (latestBlockData || []).map((item, index) => {
                    const content = JSON.parse(item.content || '');
                    const amount = content.transactions[0].input.amount / 100000000;
                    const coin = content.transactions[0].input.type;
                    const type = content.transactions[0].conversion ? 'Convert' : 'Transfer';
                    const d = new Date(Number(item.entryTimestamp) * 1000);
                    // eslint-disable-next-line max-len
                    const date = `${d.getFullYear()}-${`0${d.getMonth() + 1}`.substr(-2, 2)}-${`0${d.getDate()}`.substr(-2, 2)} ${`0${d.getHours()}`.substr(-2, 2)}:${`0${d.getMinutes()}`.substr(-2, 2)}`;

                    return (
                      <tr key={index}>
                        <td className="block-height text-color-blue">{item.directoryBlockHeight}</td>
                        <td className="transactions">{date}</td>
                        <td className="age text-color-blue">
                          <a href={`/transactions/0-${item.entryHash}`}>{item.entryHash}</a>
                        </td>
                        <td className="value-out">{`${amount} ${coin}`}</td>
                        <td className="difficulty">{type}</td>
                        <td className="extracted-by">{item.entryBlockKeyMR}</td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
}

export default compose(
  inject(STORE_KEYS.LATESTTXSTORE),
  observer,
  withProps(
    ({
      [STORE_KEYS.LATESTTXSTORE]: {
        latestTx,
      },
    }) => ({
      latestTx,
    }),
  ),
)(LatestBlock);
