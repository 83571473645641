import React from 'react';
import { compose, withProps } from 'recompose';
import { inject, observer } from 'mobx-react';

import { STORE_KEYS } from '../../stores';
import './style.scss';

function Footer(props) {
  // eslint-disable-next-line react/prop-types
  const { viewMode } = props;

  return (
    <div className={`${viewMode}_theme`}>
      <div className="footer">
        <div>Block Explorer @ 2020 PegNet</div>
      </div>
    </div>
  );
}

export default compose(
  inject(STORE_KEYS.VIEWMODESTORE),
  observer,
  withProps(
    ({
      [STORE_KEYS.VIEWMODESTORE]: {
        viewMode,
      },
    }) => ({
      viewMode,
    }),
  ),
)(Footer);
