import React from 'react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import PublicRoute from '../routes/PublicRoute';
import DetailsRoute from '../routes/DetailsRoute';
import NotFound from './NotFound';
import LatestBlock from './LatestBlock';
import RichList from './RichList';
import GeneralInformation from './GeneralInformation';
import Balances from './Balances';
import Transactions from './Transactions';

const App = (props) => (
  <Router>
    <Switch>
      <PublicRoute
        exact
        path="/"
        component={GeneralInformation}
        props={props}
      />
      <PublicRoute
        exact
        path="/latest-block"
        component={LatestBlock}
        props={props}
      />
      <DetailsRoute path="/address/:token" component={(props) => <Balances {...props} />} />
      <DetailsRoute path="/transactions/:token" component={(props) => <Transactions {...props} />} />
      <PublicRoute
        exact
        path="/rich-list"
        component={RichList}
        props={props}
      />
      <Route component={NotFound} />
    </Switch>
  </Router>
);

export default App;
