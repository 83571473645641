import React, { Component } from 'react';
import { compose, withProps } from 'recompose';
import { inject, observer } from 'mobx-react';
import './style.scss';
// import { Code } from './styles';
// import { formatNumberString } from 'pexplorer_frontend/src/utils';
// import { Code } from 'pexplorer_frontend/src/containers/Balances/styles';
import { STORE_KEYS } from '../../stores';
// import InfoCard from '../../components/Common/InfoCard';
// import { formatNumberString } from '../../utils';

class Transactions extends Component {
  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    const { token } = this.props.match.params;
    const {
      // eslint-disable-next-line react/prop-types
      getPegnetTxDetail,
    } = this.props;

    getPegnetTxDetail(token);
  }

  getXName = (sym) => {
    if (sym === 'pXBT') {
      return 'pBTC';
    }
    if (sym === 'pXBC') {
      return 'pBCH';
    }
    if (sym === 'pXAU') {
      return 'pGOLD';
    }
    if (sym === 'pXAG') {
      return 'pSilver';
    }
    return sym;
  }

  render() {
    const {
      // eslint-disable-next-line react/prop-types
      mTxDetail,
    } = this.props;

    // eslint-disable-next-line react/prop-types,no-nested-ternary
    const data = mTxDetail ? (mTxDetail.actions ? mTxDetail.actions[0] : {}) : {};
    const executed = data ? data.executed : '';
    const fromaddress = data ? data.fromaddress : '';
    const fromamount = data ? (data.fromamount / 1e8) : '';
    const fromasset = data ? data.fromasset : '';
    const hash = data ? data.hash : '';
    const height = data ? data.height : '';
    // const timestamp = data ? data.timestamp : '';
    const txid = data ? data.txid : '';

    const txaction = data ? data.txaction : '';
    let toamount = 0;
    let toasset = '';
    let toaddress = '';
    if (txaction === 2) { // conversion
      toaddress = fromaddress;
      toamount = data ? (data.toamount / 1e8) : 0;
      toasset = data ? data.toasset : '';
    } else { // transfer
      const outputs = data ? data.outputs : '';
      toaddress = outputs ? outputs[0].address : '';
      toamount = outputs ? (outputs[0].amount / 1e8) : '';
      toasset = fromasset;
    }

    /**
     *  Rendering
     */
    return (
      <>
        <section className="address-detail-section">
          <div className="container">
            <div className="sub-title">
              <h3>Transaction Details</h3>
              <p>
                All payments and transactions cost 1/10th of a cent whether you
                <br />
                send five cents or five million dollars.
              </p>
            </div>
            <div className="table-address">
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <td className="name">Transaction ID:</td>
                    <td>{txid}</td>
                  </tr>
                  <tr>
                    <td className="name">Type:</td>
                    <td>
                      {txaction === 2 ? 'Conversion' : 'Transfer'}
                    </td>
                  </tr>
                  <tr>
                    <td className="name">Inputs:</td>
                    <td>
                      {`${fromamount} ${fromasset}`}
                    </td>
                  </tr>
                  <tr>
                    <td className="name">Outputs:</td>
                    <td>
                      {toamount ? `${toamount} ${toasset}` : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td className="name">Status:</td>
                    <td>
                      {executed > 0 ? 'COMPLETED' : 'PENDING'}
                    </td>
                  </tr>
                  <tr>
                    <td className="name">Height:</td>
                    <td>
                      {height}
                    </td>
                  </tr>
                  <tr>
                    <td className="name">Confirmations:</td>
                    <td>
                      {executed > 0 ? '10+' : '0'}
                    </td>
                  </tr>
                  <tr>
                    <td className="name">Parent Entry:</td>
                    <td>
                      {hash}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>
        <section className="address-detail-section">
          <div className="container">
            <div className="table-address">
              <div className="left">
                <div className="sub-title">
                  <h3>Input</h3>
                </div>
                <table className="table table-striped">
                  <tbody>
                    <tr>
                      <td className="name">From Address:</td>
                      <td>
                        {/* eslint-disable-next-line react/jsx-no-target-blank */}
                        <a href={`/address/${fromaddress}`}>{fromaddress}</a>
                      </td>
                    </tr>
                    <tr>
                      <td className="name">Amount:</td>
                      <td>
                        {`${fromamount} ${fromasset}`}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <div className="sub-title">
                  <h3>Output</h3>
                </div>
                <table className="table table-striped">
                  <tbody>
                    <tr>
                      <td className="name">To Address:</td>
                      <td>
                        {/* eslint-disable-next-line react/jsx-no-target-blank */}
                        <a href={`/address/${toaddress}`}>{toaddress}</a>
                      </td>
                    </tr>
                    <tr>
                      <td className="name">Amount:</td>
                      <td>
                        {toamount ? `${toamount} ${toasset}` : '-'}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </>
    );
  }
}

export default compose(
  inject(STORE_KEYS.PEGNETCORESTORE),
  observer,
  withProps(
    ({
      [STORE_KEYS.PEGNETCORESTORE]: {
        getPegnetTxDetail,
        mTxDetail,
      },
    }) => ({
      getPegnetTxDetail,
      mTxDetail,
    }),
  ),
)(Transactions);
