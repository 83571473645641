/* eslint-disable */
import axios from 'axios';

import { AUTH_SERVER_URL } from '../../config/constants';

const getHeaders = () => ({
  headers: {
    'Content-Type': 'application/json',
  },
});

/**
 *  Network Status
 */
export const getNetworkStats = () => axios.post(`${AUTH_SERVER_URL}/api/stats/network`, {}, getHeaders())
  .then((ret) => ret ? ret.data : null)
  .catch((err) => Promise.reject(err.response ? err.response.data : null));

export const getSyncData = () => axios.post(`${AUTH_SERVER_URL}/api/stats/syncData`, {}, getHeaders())
  .then((ret) => ret ? ret.data : null)
  .catch((err) => Promise.reject(err.response ? err.response.data : null));

export const getPegRates = () => axios.post(`${AUTH_SERVER_URL}/api/stats/pegRates`, {}, getHeaders())
  .then((ret) => ret ? ret.data : null)
  .catch((err) => Promise.reject(err.response ? err.response.data : null));

/**
 *  Rich List
 */
export const getRichList = () => axios.post(`${AUTH_SERVER_URL}/api/stats/richList`, {}, getHeaders())
  .then((ret) => ret ? ret.data : null)
  .catch((err) => Promise.reject(err.response ? err.response.data : null));

/**
 *  Latest History
 */
export const getLatestHistory = () => axios.post(`${AUTH_SERVER_URL}/api/history/latest`, {}, getHeaders())
  .then((ret) => ret ? ret.data : null)
  .catch((err) => Promise.reject(err.response ? err.response.data : null));

export const getValidAddr = (address) => axios.post(`${AUTH_SERVER_URL}/api/history/validAddr`, { address }, getHeaders())
  .then((ret) => ret ? ret.data : null)
  .catch((err) => Promise.reject(err.response ? err.response.data : null));

/**
 *  Details with Address
 */
export const getPegnetBalance = (address) => axios.post(`${AUTH_SERVER_URL}/api/pegnet/balance`, { address }, getHeaders())
  .then((ret) => ret ? ret.data : null)
  .catch((err) => Promise.reject(err.response ? err.response.data : null));

export const getPegnetTransactions = (address, pageIndex) => axios.post(`${AUTH_SERVER_URL}/api/pegnet/transactions`, { address, pageIndex }, getHeaders())
  .then((ret) => ret ? ret.data : null)
  .catch((err) => Promise.reject(err.response ? err.response.data : null));

export const getPegnetTxDetail = (txid) => axios.post(`${AUTH_SERVER_URL}/api/pegnet/txDetail`, { txid }, getHeaders())
  .then((ret) => ret ? ret.data : null)
  .catch((err) => Promise.reject(err.response ? err.response.data : null));
