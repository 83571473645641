import React, { Component } from 'react';
import { compose, withProps } from 'recompose';
import { inject, observer } from 'mobx-react';
import { Wrapper, Button, Content } from './styles';
import { STORE_KEYS } from '../../../stores';

class PaginationControl extends Component {
  // constructor(props) {
  //   super(props);
  //
  // }

  componentDidMount() {

  }

  render() {
    const {
      // eslint-disable-next-line react/prop-types
      totalCount, pageIndex, nextPage, prevPage, address,
    } = this.props;
    const pages = Math.ceil(totalCount / 50);

    return (
      <Wrapper>
        <Button onClick={() => prevPage(address)}>Prev</Button>
        <Content>{`${pageIndex + 1} / ${pages}`}</Content>
        <Button onClick={() => nextPage(address)}>Next</Button>
      </Wrapper>
    );
  }
}

export default compose(
  inject(STORE_KEYS.PEGNETCORESTORE),
  observer,
  withProps(
    ({
      [STORE_KEYS.PEGNETCORESTORE]: {
        totalCount,
        pageIndex,
        nextPage,
        prevPage,
      },
    }) => ({
      totalCount,
      pageIndex,
      nextPage,
      prevPage,
    }),
  ),
)(PaginationControl);
