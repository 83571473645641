import React from 'react';
import { compose, withProps } from 'recompose';
import { inject, observer } from 'mobx-react';

import './style.scss';
import './pulse.scss';
import { STORE_KEYS } from '../../stores';

// eslint-disable-next-line no-unused-vars
const Header = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [searchKey, setSearchKey] = React.useState('');
  const {
    // eslint-disable-next-line react/prop-types
    viewMode, setViewMode, syncheight, validateAddr,
  } = props;

  const toggleContainer = React.createRef();

  const onClickOutsideHandler = (event) => {
    if (isOpen && toggleContainer.current && !toggleContainer.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener('click', onClickOutsideHandler);
  });

  const onSearchData = () => {
    if ((searchKey || '').trim().length === 0) return;
    validateAddr(searchKey)
      .then((data) => {
        if (data) {
          window.location.href = `/address/${searchKey}`;
        } else if (searchKey.includes('0-')) {
          window.location.href = `/transactions/${searchKey}`;
        } else {
          window.location.href = `/transactions/${`0-${searchKey}`}`;
        }
      });
  };

  const onInputSearchKey = (e) => {
    setSearchKey(e.target.value);
  };

  const _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSearchData();
    }
  };

  return (
    <div className={`${viewMode}_theme`}>
      <div className="app-header">
        <div className="container">
          <div className="header">
            <div className="logo">
              <a href="/"><img src="/images/logo.svg" alt="logo" /></a>
            </div>
            <div className="nav-menu" id="nav-menu">
              <div className="menu-list">
                <div className="menu-item">
                  <a href="/">NETWORK STATS</a>
                </div>
                <div className="menu-item">
                  <a href="/latest-block">LATEST BLOCKS</a>
                </div>
                <div className="menu-item">
                  <a href="/rich-list">RICH LIST</a>
                </div>
              </div>
              <div className="button-group">
                <ThemePanel setViewMode={setViewMode} />
              </div>
              <div className="collapse-nav-menu" style={{ display: isOpen ? 'flex' : 'none' }} ref={toggleContainer}>
                <div className="menu-item">
                  <a href="/">NETWORK STATUS</a>
                </div>
                <div className="menu-item">
                  <a href="/latest-block">LATEST BLOCKS</a>
                </div>
                <div className="menu-item">
                  <a href="/rich-list">RICH LIST</a>
                </div>
              </div>
              <button className="nav-button" type="button" onClick={() => setIsOpen(!isOpen)}>
                <img src="/images/bars.png" alt="" />
              </button>
            </div>
          </div>
          <div className="header-text">
            <h3>PegNet 2.0 Block Explorer</h3>
            <p>
              Up To Block
              {' '}
              {syncheight}
              {syncheight && (
                <span className="pulse" />
              )}
            </p>
          </div>
          <div className="search-form">
            <div className="search-input">
              <input
                placeholder="Search by Address / Txn Hash / Entry Hash"
                value={searchKey}
                onChange={(e) => onInputSearchKey(e)}
                onKeyDown={_handleKeyDown}
              />
              <button className="btn-search" onClick={() => onSearchData()}>
                <span><i className="fa fa-search" /></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// eslint-disable-next-line react/prop-types
const ThemePanel = ({ setViewMode }) => (
  <div className="theme-panel hidden-xs hidden-sm">
    {/* eslint-disable-next-line react/prop-types */}
    <div className="toggler" onClick={() => setViewMode()} />
  </div>
);

export default compose(
  inject(STORE_KEYS.VIEWMODESTORE, STORE_KEYS.NETWORKSTATSSTORE),
  observer,
  withProps(
    ({
      [STORE_KEYS.VIEWMODESTORE]: {
        viewMode,
        setViewMode,
      },
      [STORE_KEYS.NETWORKSTATSSTORE]: {
        syncheight,
        validateAddr,
      },
    }) => ({
      viewMode,
      setViewMode,
      syncheight,
      validateAddr,
    }),
  ),
)(Header);
