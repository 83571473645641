import React from 'react';
import { Provider } from 'mobx-react';

import PExplorer from './containers';
import Stores from './stores';

const App = () => (
  <Provider {...Stores()}>
    <PExplorer />
  </Provider>
);

export default App;
